import React, { useMemo, useState } from 'react';
import { CHECK_ASSIGN_USER, IS_USER_ACCESS_PAGE, TRANCHE_STATUS } from '../../config/constants';
import { useOutletContext } from 'react-router-dom';
import noTitlePayment from "../../webroot/images/no-title-payment.svg";
import LeadRemarkHistory from '../lead-list/LeadRemarkHistory';
import Modal from '../elements/Modal';
import Formsy from 'formsy-react';
import FormsyInputField from '../elements/FormsyInputField';
import FormsyCheckBox from '../elements/FormsyCheckBox';
import { tt_payment_details } from './formFields/titleTransferFormFields.json';
import FormsySelect from '../elements/FormsySelect';
import { useSelector } from 'react-redux';

const TitleTransferPayments = (props) => {
    let { bankList, numPayments, ttPaymentDetails, handleEditChange, handleDeleteChange, setTtPaymentDetails, disabledbutton, setDisabledbutton, handleInputChange, isADPCase, handleSubmit } = props;
    const { lead, remarksHistory, leadDetail } = useSelector(({masterdata, lead}) => ({
      masterdata,
      lead,
      remarksHistory:lead.remarksHistory || [],
      leadDetail: lead.leadDetail
    }));
    const [allowSubmit, setAllowSubmit] = useState(false);
    const [showremarkform,setShowremarkform]=useState(false);
    const [ViewTimeLine, setViewTimeLine] = useState(false);
    const getContext = useOutletContext();
    let accessCondition = !IS_USER_ACCESS_PAGE("payment_verification", "payment_verification")?.is_edit_access;
    if(!accessCondition){
        accessCondition=CHECK_ASSIGN_USER(lead?.leadDetail?.assign_to);
    }

    const showModalViewTimeLine = () => {
        setViewTimeLine(true);
        setShowremarkform(true);
        document.body.classList.add("overflow-hidden");
    };

    const hideModalViewTimeLine = () => {
        setViewTimeLine(false);
        document.body.classList.remove("overflow-hidden");
    };

    const handleCheckboxChange = (event, index) => {
        const modifiedData = ttPaymentDetails.map((data) => {
          if (data?.index === index) {
            return {
              ...data,
              is_tranche_marked: event.target.checked ? true : false,
            };
          }
          return data;
        });
        if(!ttPaymentDetails?.[index]?.is_tranche_marked) event.target.checked ? setDisabledbutton(false): setDisabledbutton(true);
        setTtPaymentDetails(modifiedData);
    }

    const isCheckboxChecked = (index) => {
        const isChecked = ["requested", "paid"].includes(leadDetail?.title_payment?.find(data => data.payment_type === ttPaymentDetails[index]?.payment_type)?.tranche_status) || ttPaymentDetails[index]?.is_tranche_marked;
        if (isChecked === undefined) return false;
        return isChecked;
    };
    // All the conditions can be combined here for button enable/disable
    let isSubmitDisabled = useMemo(() => {
        if (leadDetail) {
          let currentStatus = leadDetail?.status_id;
          let currentSubStatus = leadDetail?.oti_sub_status_id;
          let currentDocStatus = leadDetail?.doc_status;
          let statusMarkedDealerIncentivePaid = leadDetail?.lead_status_history?.filter(data => data.sub_status_id === 33)?.length ?  true : false;
          let allTranchesMarked = leadDetail?.title_payment?.filter(payment => ["requested", "paid"].includes(payment.tranche_status) && payment?.status === '1')?.length === 2;
          let paymentTrancheMarked = leadDetail?.split_payment?.filter(split => ["requested", "paid"].includes(split.tranche_status) && ["split_dealer", "split_dealer2"].includes(split.payment_type))?.length;
          return !allowSubmit || paymentTrancheMarked || (([5].includes(currentStatus)) || ([1].includes(currentSubStatus)) || currentDocStatus==='0' || allTranchesMarked || statusMarkedDealerIncentivePaid);
        }
        return true;
      }, [allowSubmit,leadDetail]);
    const sectionInfo = {section:"Payment verification" , sub_section:""}

  return (
    <div className="image-form-outer">
            <div className="lead-detail-from-outer w-100">
                { numPayments === 0 && !ttPaymentDetails?.filter(data => data.status === "1")?.length ?  
                    <div className="row">
                        <div className="verfication-img-no-image no-payment-bg col-md-12">
                            <div className='round-bg'>
                            <img alt="No Payment Requested" src={noTitlePayment} className="" />
                            </div>
                            <span className="no-img-txt">No Payment Requested</span>
                        </div>
                    </div>
                :
                <Formsy
                    className="lead-form-filed"
                    autoComplete="off"
                    onValid={() => setAllowSubmit(true)}
                    onInvalid={() => setAllowSubmit(false)}
                    onValidSubmit={handleSubmit}
                >
                { 
                    ttPaymentDetails?.filter(data => data.status === '1')?.map((payment, i) => {
                        return (
                          <div class="row">
                            <div className="col-md-12">
                              <div className='split-act-btn heading-tranches'> 
                              <FormsyCheckBox
                                  id="cb_pa_sameas_cra"
                                  name="cb_pa_sameas_cra"
                                  type="checkbox"
                                  className="custom-control-input"
                                  value="1"
                                  checked={isCheckboxChecked(payment?.index)}
                                  onChange={(e) => handleCheckboxChange(e,payment?.index)}
                                  disabled={(['requested','paid'].includes(leadDetail?.title_payment?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status))}
                              />
                                  <h3>{payment?.label}</h3>
                                  {ttPaymentDetails?.length > 0 &&
                                  <span className={ttPaymentDetails?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status && 'btn-primary-accent history-btn tranche-status' || ''}>{TRANCHE_STATUS[ttPaymentDetails?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status]}</span>}
                                  { !(['requested','paid'].includes(leadDetail?.title_payment?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status)) ?
                                    <ul className='action-btn'>
                                      { payment?.is_edit && payment?.id !== 0 &&
                                        <li className='edit-icon check-icon' onClick={(e)=>handleEditChange(payment?.index)}><i class="ic-check"></i></li> 
                                      }
                                    { !payment?.is_edit && payment?.id !== 0 && <li className='btn-edit' onClick={e => handleEditChange(payment?.index)}><i className="ic-edit"></i></li>}
                                    { !(['on_hold'].includes(leadDetail?.title_payment?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status)) && <li className='btn-delete' onClick={e => handleDeleteChange(payment?.index)}><i class="ic-delete-icn"></i></li>}
                                    </ul> : null
                                  }
                              </div>
                            </div>
                            {
                                tt_payment_details?.map((field, index) => {
                                    return (
                                        <>
                                        { ["text", "pattern-format", "number-format"].includes(field.type) ? (
                                                <fieldset class="form-filed col-md-6 ">
                                                    <FormsyInputField
                                                        id={"tt_payment_"+field.id}
                                                        name={field.name}
                                                        type={field.type}
                                                        value={payment && (payment[field.name] || "")}
                                                        placeholder=" "
                                                        {...(field.type === "number-format" && field.name === 'amount') ? {
                                                        thousandsGroupStyle:"thousand",
                                                        thousandSeparator: ',',
                                                        decimalSeparator: '.'
                                                        }: {}}
                                                        label={field.label}
                                                        onChange={(e) => handleInputChange(field.id, e, field.type, payment?.index)}
                                                        format={field.ApplyNumberformat ? field.format : ""}
                                                        validations={field.validations}
                                                        validationError={field.validationError}
                                                        required={isCheckboxChecked(payment?.index) && field.required}
                                                        disabled={(['requested','paid'].includes(leadDetail?.title_payment?.find(tt_payment => tt_payment.payment_type === payment?.payment_type)?.tranche_status)) || !payment?.is_edit}
                                                    />
                                                </fieldset>
                                                ) 
                                                : field.type === "dropdown" ? (
                                                    <fieldset class="single-select col-md-6">
                                                      <FormsySelect
                                                        name={field.name}
                                                        id={field.id}
                                                        inputProps={{
                                                          options: bankList,
                                                          placeholder: field.label,
                                                          className: "react-select",
                                                          classNamePrefix: "react-select",
                                                          value: bankList?.filter(({ value }) => payment?.bank_name === value),
                                                          isDisabled: (['requested','paid'].includes(leadDetail?.title_payment?.find(data => data.payment_type === payment?.payment_type)?.tranche_status) || !payment?.is_edit)}}
                                                        value={payment?.bank_name}
                                                        onChange={(e) => handleInputChange(field.id, e, field.type, payment?.index)}
                                                        required={isCheckboxChecked(payment?.index) && field?.required}
                                                      />
                                                    </fieldset>
                                                    ) 
                                                  : null 
                                        }
                                        </>
                                    )})
                            }
                        </div>
                    )})
                }
                    
                    
                    <div className="btn-save-remarks">
                    <br></br>
                    {(isSubmitDisabled || disabledbutton || accessCondition || !allowSubmit) ?  <span className="span-disabled">Save & Next</span> :
                    <button type="submit" className="btn-primary" disabled={(isSubmitDisabled || disabledbutton || accessCondition) }>
                        Save & Next
                    </button>
                    }
                    <button type="button" className="btn-line" onClick={showModalViewTimeLine}>Remarks
                        <span className="m-xs-l">({remarksHistory && (remarksHistory.length || 0)})</span>
                    </button>
                    </div>
                </Formsy>
                }
            </div>
        <div className="view-timeline-popup">
          <Modal show={ViewTimeLine} handleClose={hideModalViewTimeLine} >
            <div className="modal-header">
              <h2>Remark History</h2>
            </div>
            <LeadRemarkHistory showremarkform={showremarkform} markAsFreezed={getContext.markAsFreezed} accessCondition={accessCondition} sectionInfo={sectionInfo}/>
          </Modal>
        </div>
    </div>
  );
}
export default TitleTransferPayments;